import * as React from "react";
import { useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import Header from "../common/Header";
import DatePicker from "../common/DatePicker";
import ReportTable from "../common/ReportTable";
import { Button } from "@material-ui/core";
import SearchBar from "material-ui-search-bar";
import { remove_duplicates_es6 } from "../../helpers/helper";
import { Loading } from "../Loading";
import { buildByDateRange } from "../../handlers/reports";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import moment from "moment";
import { MultiSelect } from "react-multi-select-component";
import { getUserStoreNameFromLocalStorage } from "../../helpers/localStorageHelper";
import { addMonths, subDays, subMonths } from "date-fns";

const API = process.env.REACT_APP_AUDIENCE || 3001;
export function BuildByDateRangeReport() {
  const noData: any = [];
  const { getAccessTokenSilently } = useAuth0();
  let currentDate = new Date();
  currentDate.setMonth(currentDate.getMonth() - 11);
  const [reportData, setReportData] = useState([]);
  const [databackUp, setDataBackup]: any = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [productData, setProductData] = useState([]);
  const [groupData, setGroupData] = useState([]);
  const [fromSelectedDate, setFromSelectedDate] = useState(subDays(new Date(), 7));
  const [minToSelectedDate, setMinToSelectedDate] = useState(subDays(new Date(), 7));
  const [maxToSelectedDate, setMaxToSelectedDate] = useState(addMonths(new Date(), 6));
  const [product, setProduct] = useState("");
  const [csvData, setCSVData] = useState([]);
  const [group, setGroup] = useState("");
  const [msg, setMsg] = useState("");
  const [toSelectedDate, setToSelectedDate] = useState(new Date());
  const [state, setState] = useState({
    error: null,
    loading: false,
  });
  const [order, setorder] = useState("ASC");

  const DaysOptions = [
    { label: "Sunday", value: 0 },
    { label: "Monday", value: 1 },
    { label: "Tuesday", value: 2 },
    { label: "Wednesday", value: 3 },
    { label: "Thursday", value: 4 },
    { label: "Friday", value: 5 },
    { label: "Saturday", value: 6 },
  ];
  const ColumOptions = [
    { label: "Planned", value: "Planned" },
    { label: "Actual", value: "Actual" },
    { label: "Take Me Home", value: "Take Me Home" },
    { label: "Waste", value: "Waste" },
    { label: "Run Out", value: "Run Out" },
    { label: "Notes", value: "Notes" },
  ];
  const [selected, setSelected] = useState([]);
  var [selectedColumn, setSelectedColumn]: any = useState([]);

  const [headers, setHeaders] = useState([
    "Date",
    "Group",
    "Product",
    "Planned",
    "Actual",
    "Take Me Home",
    "Waste",
    "Run Out",
    "Notes",
  ]);
  const [headersbkp, setHeadersbkp] = useState([
    "Date",
    "Group",
    "Product",
    "Planned",
    "Actual",
    "Take Me Home",
    "Waste",
    "Run Out",
    "Notes",
  ]);

  const [tableBody, setTableBody] = useState([
    "buildDate",
    "group",
    "product",
    "plannedQuantity",
    "actualQuantity",
    "takeHomeQuantity",
    "wasteQuantity",
    "Run Out",
    "notes",
  ]);
  const [tableBodybkp, setTableBodybkp] = useState([
    "buildDate",
    "group",
    "product",
    "plannedQuantity",
    "actualQuantity",
    "takeHomeQuantity",
    "wasteQuantity",
    "Run Out",
    "notes",
  ]);
  // AdminRedirect();
  const handleFromDateChange = (date: any) => {
    setFromSelectedDate(date);
    setMinToSelectedDate(date);
    setMaxToSelectedDate(addMonths(new Date(date), 6));
    setToSelectedDate(date);
  };
  const handleToDateChange = (date: any) => {
    let enteredDate = new Date(date);
    enteredDate.setMonth(enteredDate.getMonth());
    setToSelectedDate(date);
  };
  const getProductList = (dat: any) => {
    let productList: any = [];
    if (dat && dat.length) {
      productList = dat.map((prod: any) => {
        return prod.product;
      });
    }
    const uniq: any = remove_duplicates_es6(productList);
    setProductData(uniq);
  };
  const getGroupList = (dat: any) => {
    let groupList: any = [];
    if (dat && dat.length) {
      groupList = dat.map((prod: any) => {
        return prod.group;
      });
    }
    const uniq: any = remove_duplicates_es6(groupList);
    setGroupData(uniq);
  };
  const getCSVData = (dat: any) => {
    let csvData: any = [];
    if (dat && dat.length) {
      csvData = dat.map((prod: any) => {
        var newCSV = {
          ...prod,
          notes:
            prod.notes != "" && prod.notes != null
              ? prod.notes
              : "N/A" +
                "\n " +
                (prod.takeHomeNotes != "" && prod.takeHomeNotes != null
                  ? "---------------\n" + prod.takeHomeNotes
                  : ""),
          buildDate: moment(prod.buildDate).format("DD/MM/YYYY"),
        };
        return newCSV;
      });
    }
    const uniq: any = remove_duplicates_es6(csvData);
    var csvDataupdated = uniq.map((prod: any) => {
      delete prod.takeHomeNotes;
      return {
        Date: prod.buildDate,
        Group: prod.group,
        Product: prod.product,
        Planned: prod.plannedQuantity,
        Actual: prod.actualQuantity,
        "Take Me Home": prod.takeHomeQuantity,
        Waste: prod.wasteQuantity,
        "Run Out":
          prod.runOutTime == null
            ? ""
            : moment(prod.runOutTime).format("HH:mm"),
        Notes: prod.notes,
      };
    });

    if (selectedColumn.length > 0) {
      if (!selectedColumn.some((x: any) => x.value === "Date")) {
        csvDataupdated.map((upc: any) => {
          delete upc.Date;
        });
      }
      if (!selectedColumn.some((x: any) => x.value === "Group")) {
        csvDataupdated.map((upc: any) => {
          delete upc.Group;
        });
      }
      if (!selectedColumn.some((x: any) => x.value === "Product")) {
        csvDataupdated.map((upc: any) => {
          delete upc.Product;
        });
      }
      if (!selectedColumn.some((x: any) => x.value === "Planned")) {
        csvDataupdated.map((upc: any) => {
          delete upc.Planned;
        });
      }
      if (!selectedColumn.some((x: any) => x.value === "Actual")) {
        csvDataupdated.map((upc: any) => {
          delete upc.Actual;
        });
      }
      if (!selectedColumn.some((x: any) => x.value === "Take Me Home")) {
        csvDataupdated.map((upc: any) => {
          delete upc["Take Me Home"];
        });
      }
      if (!selectedColumn.some((x: any) => x.value === "Waste")) {
        csvDataupdated.map((upc: any) => {
          delete upc.Waste;
        });
      }
      if (!selectedColumn.some((x: any) => x.value === "Run Out")) {
        csvDataupdated.map((upc: any) => {
          delete upc["Run Out"];
        });
      }
      if (!selectedColumn.some((x: any) => x.value === "Notes")) {
        csvDataupdated.map((upc: any) => {
          delete upc.Notes;
        });
      }
    }

    setCSVData(csvDataupdated);
  };
  const generateReport = async () => {
    try {
      setState({ ...state, error: null, loading: true });
      let dat = await buildByDateRange(
        fromSelectedDate,
        toSelectedDate,
        getAccessTokenSilently
      );
      if (!dat || (dat && dat.length === 0)) {
        setReportData([]);
        setDataBackup([]);
        setMsg("No Records Found.");
      }

      var selectedupdate = selected.map((prod: any) => {
        delete prod.label;
        return { value: prod.value };
      });

      if (selectedColumn.length > 0)
        selectedColumn = [
          { label: "Date", value: "Date" },
          { label: "Group", value: "Group" },
          { label: "Product", value: "Product" },
          ...selectedColumn,
        ];
      var selectedColumns: any = selectedColumn.map((prod: any) => {
        delete prod.label;
        return prod.value;
      });

      if (selectedColumns.length > 0) setHeaders(selectedColumns);
      if (selectedColumns.length === 0) {
        setHeaders(headersbkp);
      }

      var selectedColumnValues: any = selectedColumns.map(
        (prod: any, index: any) => {
          if (prod === "Date") {
            return "buildDate";
          }
          if (prod === "Group") {
            return "group";
          }
          if (prod === "Product") {
            return "product";
          }
          if (prod === "Planned") {
            return "plannedQuantity";
          }
          if (prod === "Actual") {
            return "actualQuantity";
          }
          if (prod === "Take Me Home") {
            return "takeHomeQuantity";
          }
          if (prod === "Take Me Home") {
            return "takeHomeQuantity";
          }
          if (prod === "Waste") {
            return "wasteQuantity";
          }
          if (prod === "Run Out") {
            return "Run Out";
          }
          if (prod === "Notes") {
            return "notes";
          }
        }
      );

      if (selectedColumns.length > 0) setTableBody(selectedColumnValues);
      if (selectedColumns.length === 0) {
        setTableBody(tableBodybkp);
      }

      if (selectedupdate.length > 0) {
        dat = dat.filter(function (e: any, index: any) {
          return selectedupdate.some(
            (x) => x.value === new Date(e.buildDate).getDay()
          );
        });
      }

      dat = dat.sort((a: any, b: any) =>
        a.buildDate < b.buildDate ? 1 : b.buildDate < a.buildDate ? -1 : 0
      );
      setReportData(dat);
      setDataBackup(dat);
      getProductList(dat);
      getGroupList(dat);
      getCSVData(dat);
      setState({ ...state, error: null, loading: false });
    } catch (error:any) {
      setReportData([]);
      setDataBackup([]);
      setState({ ...state, error, loading: false });
    }
  };
  const onSearchData = (value: string) => {
    if (value == "") {
      setReportData(databackUp);
      return;
    }
    const lowercasedFilter = value.toLowerCase();
    const result = databackUp.filter(
      (el: any) =>
        el.product.toLowerCase().includes(lowercasedFilter) ||
        el.group.toLowerCase().includes(lowercasedFilter)
    );
    var uniq: any = remove_duplicates_es6(result);
    setReportData(uniq);
    //todo
  };
  const onClearSearch = () => {
    setSearchValue("");
    setReportData(databackUp);
    //todo
  };
  const handleProductChange = (event: any) => {
    setProduct(event.target.value);
    if (event.target.value === "" || event.target.value === "Select") {
      setReportData(databackUp);
      return;
    }
    const filteredProduct = databackUp.filter(
      (prod: any) => prod.product === event.target.value
    );
    setReportData(filteredProduct);
  };
  const handleGroupChange = (event: any) => {
    setGroup(event.target.value);
    if (event.target.value === "" || event.target.value === "Select") {
      setReportData(databackUp);
      return;
    }
    const filteredGroup = databackUp.filter(
      (prod: any) => prod.group === event.target.value
    );
    setReportData(filteredGroup);
  };
  if (state.loading) {
    return <Loading />;
  }
  //const headers = ['Date', 'Group', 'Product', 'Planned', 'Actual', 'Take Me Home', 'Waste','Run Out','Notes'];
  //const tableBody = ['buildDate', 'group', 'product', 'plannedQuantity', 'actualQuantity', 'takeHomeQuantity', 'wasteQuantity','Run Out','notes'];
  var userStoreName = getUserStoreNameFromLocalStorage();
  
  const headerObject = {
    title: `SOUL ORIGIN (${userStoreName})`,
    subTitle: "Build By Date Range",
  };
  const handleDayChange = (event: any) => {
    setSelected(event.target.value);
  };
  // const [data, setdata] = useState(reportData);
  let sortedData = reportData;
  const sortTable = (sortedColumn: any) => {
    if (order === "ASC") {
      if (sortedColumn === "Date") {
        // const sortedData=[...reportData].sort((a:any,b:any) => (a[sortedColumn].toLowerCase()> b[sortedColumn].toLowerCase()) ? 1 : -1)
        sortedData = [...reportData].sort((a: any, b: any) =>
          a.buildDate < b.buildDate ? 1 : b.buildDate < a.buildDate ? -1 : 0
        );
      }
      if (sortedColumn === "Group") {
        // const sortedData=[...reportData].sort((a:any,b:any) => (a[sortedColumn].toLowerCase()> b[sortedColumn].toLowerCase()) ? 1 : -1)
        sortedData = [...reportData].sort((a: any, b: any) =>
          a.group < b.group ? 1 : b.group < a.group ? -1 : 0
        );
      }
      if (sortedColumn === "Product") {
        sortedData = [...reportData].sort((a: any, b: any) =>
          a.product < b.product ? 1 : b.product < a.product ? -1 : 0
        );
      }
      if (sortedColumn === "Planned") {
        sortedData = [...reportData].sort((a: any, b: any) =>
          a.plannedQuantity < b.plannedQuantity
            ? 1
            : b.plannedQuantity < a.plannedQuantity
            ? -1
            : 0
        );
      }
      if (sortedColumn === "Actual") {
        sortedData = [...reportData].sort((a: any, b: any) =>
          a.actualQuantity < b.actualQuantity
            ? 1
            : b.actualQuantity < a.actualQuantity
            ? -1
            : 0
        );
      }
      if (sortedColumn === "Take Me Home") {
        sortedData = [...reportData].sort((a: any, b: any) =>
          a.takeHomeQuantity < b.takeHomeQuantity
            ? 1
            : b.takeHomeQuantity < a.takeHomeQuantity
            ? -1
            : 0
        );
      }
      if (sortedColumn === "Waste") {
        sortedData = [...reportData].sort((a: any, b: any) =>
          a.wasteQuantity < b.wasteQuantity
            ? 1
            : b.wasteQuantity < a.wasteQuantity
            ? -1
            : 0
        );
      }
      if (sortedColumn === "Run Out") {
        sortedData = [...reportData].sort((a: any, b: any) =>
          a["Run Out"] < b["Run Out"] ? 1 : b["Run Out"] < a["Run Out"] ? -1 : 0
        );
      }
      if (sortedColumn === "Notes") {
        sortedData = [...reportData].sort((a: any, b: any) =>
          a.notes < b.notes ? 1 : b.notes < a.notes ? -1 : 0
        );
      }
      setorder("DESC");
    }
    if (order === "DESC") {
      if (sortedColumn === "Date") {
        sortedData = [...reportData].sort((a: any, b: any) =>
          a.buildDate > b.buildDate ? 1 : b.buildDate > a.buildDate ? -1 : 0
        );
      }
      if (sortedColumn === "Group") {
        sortedData = [...reportData].sort((a: any, b: any) =>
          a.group > b.group ? 1 : b.group > a.group ? -1 : 0
        );
      }
      if (sortedColumn === "Product") {
        sortedData = [...reportData].sort((a: any, b: any) =>
          a.product > b.product ? 1 : b.product > a.product ? -1 : 0
        );
      }
      if (sortedColumn === "Planned") {
        sortedData = [...reportData].sort((a: any, b: any) =>
          a.plannedQuantity > b.plannedQuantity
            ? 1
            : b.plannedQuantity > a.plannedQuantity
            ? -1
            : 0
        );
      }

      if (sortedColumn === "Actual") {
        sortedData = [...reportData].sort((a: any, b: any) =>
          a.actualQuantity > b.actualQuantity
            ? 1
            : b.actualQuantity > a.actualQuantity
            ? -1
            : 0
        );
      }
      if (sortedColumn === "Take Me Home") {
        sortedData = [...reportData].sort((a: any, b: any) =>
          a.takeHomeQuantity > b.takeHomeQuantity
            ? 1
            : b.takeHomeQuantity > a.takeHomeQuantity
            ? -1
            : 0
        );
      }
      if (sortedColumn === "Waste") {
        sortedData = [...reportData].sort((a: any, b: any) =>
          a.wasteQuantity > b.wasteQuantity
            ? 1
            : b.wasteQuantity > a.wasteQuantity
            ? -1
            : 0
        );
      }
      if (sortedColumn === "Run Out") {
        sortedData = [...reportData].sort((a: any, b: any) =>
          a["Run Out"] > b["Run Out"] ? 1 : b["Run Out"] > a["Run Out"] ? -1 : 0
        );
      }
      if (sortedColumn === "Notes") {
        sortedData = [...reportData].sort((a: any, b: any) =>
          a.notes > b.notes ? 1 : b.notes > a.notes ? -1 : 0
        );
      }

      setorder("ASC");
    }
    setReportData(sortedData);
  };

  return (
    <React.Fragment>
      <div className="row ml-lg-3 mt-md-3 text-left">
        <Header {...headerObject} />

        <div>
          <div className="row m-lg-3 justify-content-left w-100 mt-5 mx-auto">
            <DatePicker
              dpLabel="From Date"
              selectedDate={fromSelectedDate}
              handleDateChange={handleFromDateChange}
              minAllowedDate={subMonths(new Date(), 11)}
              maxAllowedDate={new Date()}
            />
            <DatePicker
              dpLabel="To Date"
              selectedDate={toSelectedDate}
              handleDateChange={handleToDateChange}
              minAllowedDate={minToSelectedDate}
              maxAllowedDate={maxToSelectedDate}
            />
            <React.Fragment>
              <div className="col-md-3 mb-md-4 mt-md-2">
                Days
                <MultiSelect
                  options={DaysOptions}
                  value={selected}
                  onChange={setSelected}
                  labelledBy="Days"
                  disableSearch={true}
                  shouldToggleOnHover={true}
                />
              </div>
              <div className="col-md-3 mb-md-4 mt-md-2">
                Columns
                <MultiSelect
                  options={ColumOptions}
                  value={selectedColumn}
                  onChange={setSelectedColumn}
                  labelledBy="Column"
                  disableSearch={true}
                  shouldToggleOnHover={true}
                />
              </div>
            </React.Fragment>

            <React.Fragment>
              <div className="col-md-3 mb-md-4 mt-md-3">
                <Button
                  onClick={generateReport}
                  variant="contained"
                  color="primary"
                  className="btn btn-primary ctm-btn"
                >
                  Generate Report
                </Button>
              </div>
            </React.Fragment>
          </div>
          {databackUp.length === 0 && (
            <div className="no-rcd">
              <p>{msg}</p>{" "}
            </div>
          )}
          {databackUp.length > 0 && (
            <div>
              <SearchBar
                value={searchValue}
                className="w-100 ml-auto border br-8 shade-0 my-5"
                onChange={onSearchData}
                onCancelSearch={onClearSearch}
              />
              <FormControl variant="outlined" className="col col-lg-6">
                <InputLabel htmlFor="outlined-product">Product</InputLabel>
                <Select
                  native
                  value={product}
                  onChange={handleProductChange}
                  label="Product"
                  inputProps={{
                    name: "product",
                    id: "outlined-product",
                  }}
                >
                  <option aria-label="None" value="" />
                  {productData.map((item: string, index: any) => (
                    <option key={index} value={item}>
                      {item}
                    </option>
                  ))}
                </Select>
              </FormControl>
              <FormControl variant="outlined" className="col col-lg-6">
                <InputLabel htmlFor="outlined-group">Group</InputLabel>
                <Select
                  native
                  value={group}
                  onChange={handleGroupChange}
                  label="Group"
                  inputProps={{
                    name: "group",
                    id: "outlined-group",
                  }}
                >
                  <option aria-label="None" value="" />
                  {groupData.map((item: string, index: any) => (
                    <option key={index} value={item}>
                      {item}
                    </option>
                  ))}
                </Select>
              </FormControl>
              {reportData.length > 0 && (
                <ReportTable
                  sortTable={sortTable}
                  tbName="Build_By_Date_Range"
                  tbHeaders={headers}
                  tbData={reportData}
                  tbBody={tableBody}
                  csvData={csvData}
                  dtFormat="DD/MM/YYYY"
                />
              )}
            </div>
          )}
        </div>
      </div>
    </React.Fragment>
  );
}
