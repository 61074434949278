import React from "react";
import TableContainer from "@material-ui/core/TableContainer";
import Paper from "@material-ui/core/Paper";
import jsPDF from "jspdf";
import Button from "@material-ui/core/Button";
import html2canvas from "html2canvas";
import { CSVLink } from "react-csv";
import moment from "moment";

export default function ReportTable(props: any) {
  const { tbName, tbData, tbHeaders, tbBody, dtFormat, csvData } = props;
  let customClass = "col mx-auto";
  const headerLength = tbHeaders.length;
  if (headerLength === 3) {
    customClass = "col-lg-4";
  } else if (headerLength === 4) {
    customClass = "col-lg-3";
  }
  const printDocument = () => {
    const input: any = document.getElementById(tbName);
    html2canvas(input).then((canvas) => {
      var imgWidth = 200;
      var pageHeight = 290;
      var imgHeight = (canvas.height * imgWidth) / canvas.width;
      var heightLeft = imgHeight;
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF("p", "mm", "a4");
      var position = 0;
      var heightLeft = imgHeight;
      pdf.addImage(imgData, "JPEG", 0, position, imgWidth, imgHeight);
      pdf.save(`${tbName}.pdf`);
    });
  };

  const sortTable = (showAllStatus: any) => {
    props.sortTable(showAllStatus);
  };

  const centerAlignedCol = [
    "Planned",
    "Actual",
    "Take Me Home",
    "Waste",
    "Qty",
    "Days with actual > planned",
    "Days with Planned > Actual",
  ];

  return (
    <div>
      <TableContainer id={tbName} className="txt" component={Paper}>
        <div className="mx-2 row bg-header mb-0 py-3 rounded mt-3" id={tbName}>
          {tbHeaders.map((header: any) => (
            <div
              className={
                centerAlignedCol.indexOf(header) > -1
                  ? `text-center ${customClass}`
                  : customClass
              }
            >
              <h5
                className="mb-0"
                style={{ cursor: "pointer" }}
                onClick={() => sortTable(header)}
              >
                {header}
              </h5>
            </div>
          ))}
        </div>
        {tbData.map((p: any, index: any) => (
          <div
            key={index}
            className="mx-2 row shade-row mb-1 py-2 rounded mt-3 table-font"
            style={{
              background:
                p.plannedQuantity != null
                  ? p.plannedQuantity != p.actualQuantity
                    ? "rgb(255, 195, 0,0.15)"
                    : ""
                  : "",
            }}
          >
            {tbBody.map((attr: any) => (
              <div
                className={
                  Number(p[attr]).toString() === "NaN"
                    ? customClass
                    : `text-center ${customClass}`
                }
              >
                {attr == "note" ? (
                  <text style={{ whiteSpace: "pre-wrap" }}>
                    {p.note != null ? p.note : "N/A"}
                  </text>
                ) : attr.includes("notes") ? (
                  <text style={{ whiteSpace: "pre-wrap" }}>
                    {(p.notes != "" ? p.notes : "N/A") +
                      "\n " +
                      (p.takeHomeNotes != ""
                        ? "---------------\n" + p.takeHomeNotes
                        : "")}
                  </text>
                ) : (
                  <p className="mb-0">
                    {attr.includes("Date") ||
                    (attr.includes("Time") && !attr.includes("Run Out"))
                      ? moment(p[attr]).format(dtFormat)
                      : attr.includes("Run Out")
                      ? p.runOutTime == null
                        ? "N/A"
                        : new Date(p.runOutTime).getHours() +
                          ":" +
                          new Date(p.runOutTime).getMinutes()
                      : attr.includes("%")
                      ? p.actualQuantity == 0
                        ? 0
                        : p.containersPerTray == null
                        ? ((p.wasteQuantity / p.actualQuantity) * 100).toFixed(
                            2
                          )
                        : (
                            (p.wasteQuantity /
                              (p.actualQuantity * p.containersPerTray)) *
                            100
                          ).toFixed(2)
                      : p[attr]}{" "}
                  </p>
                )}
              </div>
            ))}
          </div>
        ))}
      </TableContainer>
      <div className="my-5 text-right">
        {/* <Button onClick={printDocument} variant="contained" color="primary" className="btn btn-primary ctm-btn mx-2">  
          Generate PDF  
        </Button>   */}

        <CSVLink data={csvData} filename={tbName + ".csv"} target="_blank">
          <Button
            variant="contained"
            color="primary"
            className="btn btn-primary ctm-btn"
          >
            Generate CSV
          </Button>
        </CSVLink>
      </div>
    </div>
  );
}
